<template>
  <div class="header" ref="header">
    <div class="logo" ref="logo">
      <router-link to="/">
        <img src="@/assets/img/PartyFamily_Logo_Web.png" alt="">
      </router-link>
      <div class="places">DRESDEN - CHEMNITZ - LEIPZIG</div>
      <div class="menuswitch">
        <img src="@/assets/img/menu.svg" alt="" @click="toggleMenu">
      </div>
    </div>
    <div class="menu" :class="{fixed: isFixed,switched: mobileMenu }">
<!--      <router-link :to="shortLink(m.url)!='/'?shortLink(m.url):'/sortiment'" :key="m.id" v-for="m in mainmenu"-->
<!--                   v-html="m.title"></router-link>-->
      <router-link to="/konfigurator/" >WUNSCHBALLON - SHOP</router-link>
      <router-link to="/sortiment/" >Sortiment</router-link>
      <router-link to="/standorte/" >Standorte</router-link>
      <router-link to="/team-jobs/" >Team & Jobs</router-link>
      <router-link to="/faq/" >FAQ</router-link>
      <router-link to="/galerie/" >Galerie</router-link>
    </div>
    <!--    <span v-if="this.$refs.logo">{{ // this.$refs.logo.offsetHeight }}</span>-->
  </div>
</template>

<script>
import store from '@/store'
import {coreLib} from "@/mixins";
export default {
  data() {
    return {
    mobileMenu: false
    }
  },
  mixins: [coreLib],
  name: "Menu.vue",
  computed: {
    mainmenu: () => store.state.mainmenu,
    isFixed: () => store.state.fixed
  },
  updated() {

  },
  watch: {
    scrollPosition() {
      if (this.$refs.logo) {
        let logoheight = this.$refs.logo.offsetHeight
        store.commit('saveFixed', this.scrollPosition > logoheight )
        // this.isFixed = this.scrollPosition > logoheight
      }
    },
    $route: {
      handler: function() {
        this.mobileMenu = false
        store.commit('saveFixed', false )
        console.log(store.state.fixed)
      },
      deep: true,
    }
  },
  methods: {
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu
    }
  }

}
</script>

<style lang="scss">
.menuswitch {
  display: none;
  @include mobile {
    display: flex;
  }
  width: 95%;
  justify-content: flex-end;

  img {
    max-width: 32px;
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.logo {
  padding: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 300px;
  }

  .places {
    color: $lila;
    font-weight: 700;
    padding-left: 50px;
    @include phone {
      padding-left: 0;
    }
  }
}

.menu {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding: 10px 0;
  background-color: #fff;
  @include mobile {
    display: none;
    &.switched {
      display: block;
    }
    a {
      display: block;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }


  a {
    color: #444;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    @include phone {
      font-size: 24px;
      margin: 10px 0;
    }

    &.router-link-exact-active {


      color: $gruen;
      font-weight: 700;

    }
  }
}
</style>